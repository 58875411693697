import React from 'react'
import Props from 'prop-types'

import Page from 'components/Page'
import Navigation from 'components/Navigation'
import Container from 'components/Styled/Container'
import { H2, H3, H4, Paragraph, Ul } from 'components//Styled/Typography'
import {
  Section,
  PageContent,
  SECTION_LAYOUT_TEXT,
} from 'components//Styled/Layout'
import ScrollLink from 'components/ScrollLink'

const PROVIDED_ID = 'information-you-provide'
const COLLECTED_ID = 'information-we-collect'

const PrivacyPolicyTemplate = ({ pageContext: lang }) => (
  <Page title="Privacy Policy" translates={lang.lang}>
    <Navigation isMobileVisible translates={lang.lang} />
    <Container>
      <PageContent>
        <H2>Privacy Policy</H2>

        <Section layout={SECTION_LAYOUT_TEXT}>
          <H3>SIMPLIFIED SUMMARY</H3>
          <Paragraph>
            <b>Controller of personal data:</b> Cosmic Latte s.r.o., established
            in accordance with the laws of the Czech Republic, with its
            registered office at Rohanské nábřeží 678/23, Karlín, 186 00 Prague
            8, Czech Republic, ID no. 050 66 891, entered in the Commercial
            Register at the Municipal Court in Prague, Section C, Insert 257791.
          </Paragraph>
          <Paragraph>
            <b>Personal data processed:</b> We process data necessary for proper
            functioning of the Zoe App, i.e. all data that you enter into your
            profile (such as your name or date of birth). We also collect
            information about your use of our services, such as location, access
            log, activity and information from Facebook or Instagram (for
            registering via these social networks), that are necessary for the
            proper functioning of the Zoe App and performance of our contract.
          </Paragraph>
          <Paragraph>
            You have a right to delete or modify data provided to the Zoe App or
            delete your account at any time.
          </Paragraph>
          <Paragraph>
            <b>Purposes of personal data processing:</b> We are processing your
            data mainly for providing our services and for the right functioning
            of the Zoe App and performance of our contract with you.
          </Paragraph>
          <Paragraph>
            <b>Legal basis for processing:</b> We are processing your data
            mainly with respect to performance of a contract with you, further
            based on legitimate interests, for complying with applicable laws
            and based on your consent (if you provide it to us).
          </Paragraph>
          <Paragraph>
            <b>Our legitimate interests:</b> The main reasons for collecting
            data based on legitimate interests are improvement of our service,
            the safety of our users and enforcement of our rights.
          </Paragraph>
          <Paragraph>
            <b>Categories of recipients of the personal data:</b> We provide
            information that we process, including personal data, only in the
            necessary scope to third parties that provide us with services, in
            particular, cloud data centers, services for automatic evaluation of
            the content of photographs, communication, data hosting and
            maintenance providers, services for payment process, security
            operations, analytics suppliers, application developers and customer
            care center service.
          </Paragraph>
          <Paragraph>
            <b>Transfer of personal data:</b> Your personal data might be
            transferred to other countries. In this process, we pay attention to
            the highest possible data protection in compliance with the General
            Data Protection Regulation (GDPR).
          </Paragraph>
          <Paragraph>
            <b>Storage period:</b> Your personal data are stored only as long as
            we need it for legitimate reasons and as it is required by
            applicable law. In most cases, we will delete your data within 6
            months of receiving your request for their deletion or after you
            deleted your account in the application.
          </Paragraph>
          <Paragraph>
            <b>Your rights:</b> You have the right to request us to change,
            delete or update (correct) your personal data. You also have the
            right to limit their processing, to object to the processing of
            personal data or/and to receive a copy of your data.
          </Paragraph>
          <Paragraph>
            <b>Right to lodge a complaint:</b> You also have the right to lodge
            a complaint with respective supervisory authority.
          </Paragraph>

          <H3>FULL WORDING OF PRIVACY POLICY</H3>

          <Paragraph>
            Cosmic Latte s.r.o., established in accordance with the laws of the
            Czech Republic, with its registered office at Rohanské nábřeží
            678/23, Karlín, 186 00 Prague 8, Czech Republic, ID no. 050 66 891,
            entered in the Commercial Register at the Municipal Court in Prague,
            Section C, Insert 257791 (“we” or the “Cosmic Latte”) is controller
            of personal data and operator of the Zoe application (“Zoe
            application”, “Zoe App” or “Application”), a dating app for LGBTQ
            women.
          </Paragraph>
          <Paragraph>
            When you use the Zoe application and websites (“Services”) you
            entrust your data to us, including your personal data.
          </Paragraph>
          <Paragraph>
            This privacy policy will inform you of the data we collect, why we
            collect the data, how long we store the data, and what we do with
            the data.
          </Paragraph>
          <Paragraph>
            If you want to use the Zoe application, please thoroughly acquaint
            yourself with the content of this privacy policy. By signing up you
            agree with the privacy policy and you acknowledge the processing of
            personal data by the company Cosmic Latte as the controller of the
            personal data.
          </Paragraph>

          <H3>What data do we collect and process?</H3>
          <Paragraph>
            The Zoe App collects and processes personal data (such as your name
            or date of birth) and other types of data. We need this data for
            providing our Services, we cannot ovide the correct connections and
            our Application cannot work right without the data about you and
            some information about your preferences. We also collect information
            about your use of our Services, such as location, access log,
            activity and information from Facebook or Instagram, that are
            necessary for the proper functioning of the Application and
            performance of our contract.
          </Paragraph>

          <H4 id={PROVIDED_ID}>Information you provide</H4>
          <Paragraph>
            When registering on the Application, you may use the registration
            form or register through Facebook.
          </Paragraph>
          <Paragraph>
            When registering with the registration form, you provide the
            following information (if your profile is complete): your name,
            e-mail address, date of birth, sexual orientation, gender,
            relationship status, preferences (alcohol consumption, smoking,
            interests), appearance (eyes colour, hair colour, body size, tattoo,
            piercing) and photos.
          </Paragraph>
          <Paragraph>
            When registering through your Facebook account, you provide
            information from your public profile on Facebook (in accordance with
            your privacy settings on Facebook), such as your name, profile
            photo, sex, age, and your Facebook ID (user number). In addition,
            you can provide the following information to the Application: your
            e-mail address, date of birth, photograph, and your interests
            (“likes” on Facebook).
          </Paragraph>
          <Paragraph>
            When you subscribe to a paid service, you provide to our payment
            service provider (Apple, Google) payment information (card number
            and other payment information) in accordance with the Privacy Policy
            of these providers.
          </Paragraph>
          <Paragraph>
            When you contact our support team, we collect the information
            provided in this communication.
          </Paragraph>
          <Paragraph>
            The Application also allows you to set personal preferences when
            searching for suitable connections, such as age and distance.
          </Paragraph>

          <H4>Sensitive personal data</H4>
          <Paragraph>
            Although we do not intentionally collect or process any sensitive
            personal data, registration on the Zoe application or use of the Zoe
            application could be considered in certain jurisdictions a
            processing of sensitive personal data (special categories of
            personal data). As optional data in the Zoe application, you may
            also provide information about whether you are in a relationship or
            about your sexual identity or other sensitive personal data (special
            categories of personal data). You agree that you voluntary publish
            such personal data and grant us consent to their processing so that
            they may appear in your profile in the Application. You can delete
            such data from your profile anytime. Our processing of such data
            results from you providing such data to us and such data will not be
            used for any other purpose than operation of the Application.
          </Paragraph>
          <Paragraph>
            You can delete personal data by adapting your settings or contacting
            the administrator.
          </Paragraph>

          <H4>Information from other social sites</H4>
          <Paragraph>
            The Zoe application allows you to link your account in the Zoe
            application to accounts from other social sites, specifically
            Facebook, Instagram and Spotify. When linking to other social sites,
            you have the option of selecting which categories of data or what
            specific data (etc. photos) from other social sites you provide to
            the Application.
          </Paragraph>
          <Paragraph>
            When linking to an account on Facebook, the Zoe application may
            acquire, in particular, the information specified above in the
            section{' '}
            <ScrollLink to={`#${PROVIDED_ID}`}>
              “Information you provide”
            </ScrollLink>{' '}
            during registration.
          </Paragraph>
          <Paragraph>
            When linking to an account on Instagram, Zoe acquires your Instagram
            ID and access to your photographs on Instagram.
          </Paragraph>
          <Paragraph>
            You acknowledge that linking to your account on Facebook or
            Instagram is voluntary and made on the basis of your request when
            registering with the Application. Such linking is not required for
            proper operation of the Application in case you provide the
            necessary information yourself without such linking.
          </Paragraph>

          <H4>Localization data</H4>
          <Paragraph>
            Localization data, i.e. information about your current location, are
            important for the proper functioning of the Zoe application,
            especially when searching for suitable connections in your vicinity.
          </Paragraph>
          <Paragraph>
            Zoe application requests that you provide access to the localization
            data on the mobile device where the Zoe application is installed.
          </Paragraph>
          <Paragraph>
            If you allow access to your localization data, we are then able to
            collect and process information on your location. These data can be
            collected only if you are using the Application.
          </Paragraph>
          <Paragraph>
            We delete localization data immediately after they are no longer
            needed for functioning of the Application.
          </Paragraph>

          <H4>Message content</H4>
          <Paragraph>
            The Zoe application allows you to chat with other users on the Zoe
            application or to send information to us as the operator of the
            application.
          </Paragraph>
          <Paragraph>
            In all of these cases, we may collect and process the content of
            these messages (chats history).
          </Paragraph>

          <H4 id={COLLECTED_ID}>
            Information we collect when you use the Zoe application
          </H4>
          <Paragraph>
            We collect and process information about how you use the Zoe
            application and what type of devices you use.
          </Paragraph>
          <Paragraph>
            This information includes the language setting of your mobile device
            or information about how you have used our services, time when you
            joined, time you spend using the Application, the features you use,
            interaction with other users ( whether you have marked a profile of
            another user as “Like” or “Dislike”, so that we can connect you when
            there is mutual agreement), the number of messages, the number of
            swipes and the number of connections.
          </Paragraph>
          <Paragraph>
            We also collect other information about the device you use including
            information about software and hardware (IP address, device type and
            ID).
          </Paragraph>

          <H3>Voluntary nature of personal data</H3>
          <Paragraph>
            Providing any personal data is completely voluntary. However, if you
            want to use the Zoe application, you must at least provide the
            minimum scope of information specified in the section{' '}
            <ScrollLink to={`#${PROVIDED_ID}`}>
              “Information you provide”
            </ScrollLink>{' '}
            during registration in the Zoe application. The minimum required
            data are marked as “Required”. Providing other information is
            completely voluntary for the user.
          </Paragraph>
          <Paragraph>
            <ScrollLink to={`#${COLLECTED_ID}`}>
              “Information we collect when you use the Zoe application”
            </ScrollLink>{' '}
            is always collected only when you use the Zoe application.
          </Paragraph>

          <H3>How we use the information we collect</H3>
          <Paragraph>
            We use the information we collect for operating the Zoe application
            (i.e., for performing the contract that you have with us) and in
            this connection for improving and developing new services, and for
            protecting our company, you and other users of the Zoe application.
          </Paragraph>
          <Paragraph>
            In addition, we use this information for marketing and commercial
            purposes so that we can offer you relevant products and services
            from our company. Thus, we can send you a commercial communication
            (such as an SMS, MMS, e-mail, etc.) using the electronic contact you
            provided to us. You are entitled at any time, even when sending
            individual messages, to decline any other commercial communication.
          </Paragraph>
          <Paragraph>
            We can use the data we collect for the following purposes, in
            particular for:
          </Paragraph>
          <Ul>
            <li>
              Operating the Zoe application and providing related services
              (recommending you the other users and right connections),
            </li>
            <li>
              Managing your profile, providing customer support, and
              communicating with you,
            </li>
            <li>
              Making an analysis relating to how you use the Zoe application and
              what products and services you are interested in, including
              collecting and assessing data in analytic tools, such as Google
              Analytics,
            </li>
            <li>
              Creating, displaying, and monitoring advertisements adapted to
              your interests,
            </li>
            <li>
              Improving our services and developing new (new features based on
              interest our users),
            </li>
            <li>
              Preventing of criminal offences, breaches of conditions, fraud,
              verification of control notifications,
            </li>
            <li>
              Ensuring compliance with the law, assisting with law enforcement.
            </li>
          </Ul>
          <Paragraph>
            The Zoe application displays your profile created in the Zoe
            application, especially your name, photographs, age, and distance,
            and any other information acquired from your profiles on other
            social sites that you have given the Zoe application access to. When
            displaying your profile in the Zoe application, we respect the
            privacy settings for your profile and restrictions on displaying
            certain information. You may restrict the visibility of your profile
            for other Zoe application users.
          </Paragraph>
          <Paragraph>
            Our automatized systems analyze your photographs and recognize if
            there is a face, which is required, for example, in a profile photo.
            Our systems also analyze the location and profile quality of users
            based on which we can offer you the right connections with other
            users near you.
          </Paragraph>
          <Paragraph>
            We are able to connect all of the personal data we collect on the
            Zoe application with information (including personal data) from
            other services associated with the Zoe application.
          </Paragraph>

          <H3>Legal bases for process your information</H3>
          <Paragraph>
            We process your information using the following legal basis:
          </Paragraph>
          <Ul>
            <li>
              Performing the contract when we have with you and providing our
              Service;
            </li>
            <li>
              Legitimate interests – for improving our Service, for keeping our
              users safe and for enforcing our rights;
            </li>
            <li>Complying with applicable laws and regulations; and</li>
            <li>
              If you provide us with your consent, we may also process data
              based on your consent.
            </li>
          </Ul>

          <H3>To whom do we disclose the information we collect?</H3>

          <H4>Disclosure to other users of the Zoe application</H4>
          <Paragraph>
            Your profile in the Zoe application is, due to the purpose of this
            Application, visible to other Zoe application users.
          </Paragraph>
          <Paragraph>
            When displaying your profile in the Zoe application, we respect the
            privacy settings of your profile and the restrictions on certain
            information. Therefore, consider providing the data with respect to
            such publication and adjust your privacy settings based on your
            preferences.
          </Paragraph>
          <Paragraph>
            If you “Like” or “Power Message” another user and this user “Likes”
            or “Power Message” you, thus making it a “Match”, this other user is
            informed about this, and you can initiate communication with this
            user using the chat function in the Zoe application.
          </Paragraph>
          <Paragraph>
            When authorizing the connection of your profile to other social
            sites, such as Facebook, Instagram or Spotify, other information is
            also visible, such as photographs and interests, and also
            information that you have in common with another user that browses
            your profile, such as common friends or interests.
          </Paragraph>
          <Paragraph>
            If someone submits a report involving you, such as inappropriate
            behaviour or violation of our Terms & Conditions, we may communicate
            to the reporting person the actions, if any, we took because of the
            report.
          </Paragraph>

          <H4>Third-party disclosure – personal data processors</H4>
          <Paragraph>
            We provide information that we process, including personal data,
            only in the necessary scope to third parties that provide us with
            services, in particular, cloud data centers, services for automatic
            evaluation of the content of photographs, communication, data
            hosting and maintenance providers, services for payment process,
            security operations, analytics suppliers, application developers and
            customer care center service. When choosing third parties, we pay
            attention to the reputation of these subjects and require their
            strict adherence to personal data protection requirements,
            corresponding to the high standards of regulations in the European
            Union.
          </Paragraph>
          <Paragraph>
            We never provide your data to third parties for any marketing or
            commercial purpose.
          </Paragraph>

          <H4>Other disclosure</H4>
          <Paragraph>
            We could be required to provide your personal data under law or for
            fulfilling mandatory duties (e.g. for criminal, court, or
            administrative proceedings). In this case we are authorized, if
            necessary, to disclose your personal data in order to protect our
            rights or the rights of third parties or in order to protect users
            of the Zoe application.
          </Paragraph>
          <Paragraph>
            We are authorized, if necessary, to disclose your personal data in
            order to enforce our agreements with you and to mitigate the
            liability of the Cosmic Latte.
          </Paragraph>

          <H4>Child Sexual Abuse Regulation and User Confidentiality</H4>
          <Paragraph>
            At Zoe, we are committed to ensuring a safe and secure environment
            for all users. We strictly adhere to regulations addressing Child
            Sexual Abuse and Exploitation (CSAE) and operate within the legal
            framework that permits derogations from ePrivacy rules to combat
            CSAE effectively. CSAE includes any visual depiction of a minor,
            whether in photos, videos, or digitally generated content, that
            involves sexually explicit conduct or inappropriate situations. This
            may include images of minors who appear unaccompanied by a parent or
            guardian, are not fully clothed in a manner deemed inappropriate, or
            are portrayed in a way that suggests or implies sexual activity. Any
            such content is strictly prohibited and will not be tolerated on our
            platform. As part of our dedication to child safety, Zoe fully
            cooperates with local law enforcement authorities in cases involving
            potential breaches of CSAE regulations. In exceptional
            circumstances, when CSAE is identified and/or reported by law
            enforcement, authorized personnel may process and securely share
            relevant user data with the appropriate authorities to support
            investigations and uphold child protection standards. If you
            encounter any content or behavior related to CSAE, please report it
            immediately through our in-app reporting system or contact us at{' '}
            <a href="mailto:help@zoeapp.co">help@zoeapp.co</a>.
          </Paragraph>

          <H3>Your rights (Access to and updating personal data)</H3>
          <Paragraph>
            We provide you access to your personal data each time you use the
            Zoe application.
          </Paragraph>
          <Paragraph>
            You have control over your personal data. You may change, delete or
            update any of the personal data at any time in the Settings section.
          </Paragraph>
          <Paragraph>
            Whilst respecting limits and rules of the applicable regulation, you
            have the following rights:
          </Paragraph>
          <Ul>
            <li>
              the right of access to personal data, i.e. the right to obtain
              confirmation from us as to whether or not personal data concerning
              you are being processed and, if so, the right to obtain access to
              such personal data and information on:
              <Ul>
                <li>purposes of processing;</li>
                <li>the categories of personal data concerned;</li>
                <li>
                  the recipients or categories of recipients to whom the
                  personal data have been or will be disclosed;
                </li>
                <li>
                  the planned period of storage of personal data or the criteria
                  used to determine this period;
                </li>
                <li>
                  the existence of the right to request us to correct or delete
                  personal data or to limit their processing and / or to object
                  to the processing of personal data;
                </li>
                <li>
                  the right to lodge a complaint with the supervisory authority;
                </li>
                <li>
                  sources of personal data, if these are not obtained from the
                  applicant;
                </li>
                <li>
                  the implementation of automated decision-making, including
                  profiling, and information on the procedure used as well as
                  the relevance and expected consequences for the applicant;
                </li>
                <li>
                  on the transfer of personal data to countries outside the
                  European Union (so-called third countries) or international
                  organizations and on appropriate guarantees for the processing
                  of personal data provided in connection with their transfer;
                </li>
              </Ul>
            </li>
            <li>the right to receive a copy of the processed personal data;</li>
            <li>
              the right to correct personal data if his personal data are
              inaccurate;
            </li>
            <li>
              the right to have personal data erased ("right to be forgotten")
              if: personal data are no longer needed for the purposes for which
              they were collected or otherwise processed, the consent to the
              processing of personal data is revoked and there is no other legal
              reason for the processing, legitimate objections are raised
              against the processing of personal data, personal data have been
              processed illegally, personal data must be deleted in order to
              fulfil a legal obligation binding on the Cosmic Latte;
            </li>
            <li>
              the right to restrict the processing if:
              <Ul>
                <li>
                  the accuracy of personal data is denied for the time necessary
                  for us to verify the accuracy of personal data;
                </li>
                <li>
                  the processing of personal data is illegal, but instead of
                  deleting personal data, restrictions on their use are
                  required;
                </li>
                <li>
                  We no longer need personal data for processing purposes, but
                  the applicant requires them to determine, enforce or defend
                  legal claims;
                </li>
                <li>
                  an objection has been raised against the processing in the
                  case of the processing of personal data for the purposes of
                  the legitimate interests of us until it is verified whether
                  the legitimate reasons of us outweigh the legitimate reasons
                  of the objecting person;
                </li>
              </Ul>
            </li>
            <li>
              the right to data portability, i.e. the right to obtain personal
              data concerning him in a structured, commonly used and
              machine-readable format, and the right to transfer such data to
              another controller (or to request the transfer of data directly by
              us to another controller), in case of that the processing of
              personal data is based on consent or a contract and is carried out
              automatically;
            </li>
            <li>
              the right to object, ie the right to object to the processing of
              personal data for the purposes of our legitimate interests;
            </li>
            <li>
              the right to file a complaint with the Office for Personal Data
              Protection or at the competent authority.
            </li>
          </Ul>

          <Paragraph>
            In this respect, you may change the settings on your device as
            access to contacts, photo gallery, location, notifications etc.
            Please note that if you disable certain accesses, our Service may
            not be complete and lose functionality or part thereof.
          </Paragraph>
          <Paragraph>
            You may also request information or change the settings by sending a
            request to the e-mail address:{' '}
            <a href="mailto:help@zoeapp.co">help@zoeapp.co</a> or cancel your
            profile in the Zoe application at any time in Settings – Private –
            Delete Account.
          </Paragraph>
          <Paragraph>
            Please note that uninstalling the application from your device, will
            not delete your Account.
          </Paragraph>
          <Paragraph>
            For the purpose of your safety and the safety of other users, we may
            ask you to provide us with proof of your identity. We may also
            reject your requests in the case that the request is unlawful, if it
            may infringe trade secrets or the privacy of other users.
          </Paragraph>
          <Paragraph>
            If you request information relating to other users, such as message
            history, the concerned user must give us his explicit written
            consent before providing this information.
          </Paragraph>

          <H3>Storage period of your information</H3>
          <Paragraph>
            Your personal data are stored only as long as we need it for
            legitimate reasons and as it is required by applicable law. We store
            your personal data for the duration of your profile in the Zoe
            application, unless stated otherwise.
          </Paragraph>
          <Paragraph>
            After you delete your profile in the Zoe application, some of the
            data may still be stored by us, for legitimate or technical reasons,
            in our databases or the databases of our partner(s) for a limited
            period of time.
          </Paragraph>
          <Paragraph>We will delete your personal data as follows:</Paragraph>
          <Ul>
            <li>
              If you require deletion or if you delete your Account all personal
              data, including your profile, are deleted within 6 months of
              receiving the request – this period is set to protect our users
              and us and may further be prolonged in justified cases (pending
              legal action, investigation by authorities etc.). This period is
              necessary e.g. to investigate unappropriated behaviors;
            </li>
            <li>
              After 74 days of your inactivity your swipes and matches will be
              deleted.
            </li>
          </Ul>

          <h2>Protection and security of personal data</h2>
          <Paragraph>
            We make every effort to protect you from unauthorized access or
            unauthorized changes, publication, or deletion of the information we
            collect.
          </Paragraph>
          <Paragraph>
            We disclose personal data only to employees and contractual partners
            who need to process the data on our behalf. All employees and
            contractual partners are bound by the duty to uphold
            confidentiality.
          </Paragraph>
          <Paragraph>
            We protect personal data to the maximum extent possible with the
            help of advanced encryption technologies.
          </Paragraph>
          <Paragraph>
            We regularly inspect and update the process of collection, storage,
            and processing of information in order to prevent unauthorized
            access to our systems.
          </Paragraph>
          <Paragraph>
            However, we are not liable for any unauthorized infringement by
            third parties, as a result of which these parties obtain
            unauthorized access to your profiles or personal data and misuse
            these data or disclose them to third parties, if such infringement
            took place as a result of circumstances which we are not able to
            influence.
          </Paragraph>
          <Paragraph>
            If, despite all our efforts, an unauthorized leak of personal data
            will take place, we might need to notify the relevant supervisory
            authority and follow procedure prescribed by law in this respect.
          </Paragraph>

          <H3>Where are your data collected?</H3>
          <Paragraph>
            Your personal data are collected and processed mainly at our
            headquarters in the Czech Republic.
          </Paragraph>
          <Paragraph>
            In view of the fact that we use cloud services for the Zoe
            application and your personal data may be stored in data centres of
            these providers, your personal data may also be transferred to other
            EU countries and to the USA or other jurisdictions that have
            different privacy laws. All processors of personal data with a
            processing location in the USA are bound under the SCC („Standard
            contractual clauses for data transfers between EU and non-EU
            countries“) or another transfer mechanism that the competent
            authorities consider adequate. All our providers ensure the
            protection of personal data in compliance with the General Data
            Protection Regulation (GDPR) applicable in the European Economic
            Area.
          </Paragraph>

          <H3>Changes to the privacy policy</H3>
          <Paragraph>
            Our privacy policy may be amended from time to time.
          </Paragraph>
          <Paragraph>
            The current version of the privacy policy, all changes, and all
            previous versions will be available on these web pages. If it
            involves significant changes, we will prepare for you a summary and
            explanation.
          </Paragraph>
          <Paragraph>
            You acknowledge these changes to the privacy policy by continuing to
            use the Zoe application after being notified of the changes and
            their effective date. If you do not agree to the changes,
            discontinue your use of the Zoe application and delete your profile
            from the Zoe application.
          </Paragraph>

          <H3>How to contact us</H3>
          <Paragraph>
            Should you have any questions or comments relating to the privacy
            policy, please contact us at the following e-mail address:{' '}
            <a href="mailto:help@zoeapp.co">help@zoeapp.co</a> or to the address
            Cosmic Latte s.r.o., Rohanské nábřeží 678/23, Karlín, 186 00 Prague
            8, Czech Republic.
          </Paragraph>
          <Paragraph>
            This Privacy Policy was last updated 2nd February 2025.
          </Paragraph>
        </Section>
      </PageContent>
    </Container>
  </Page>
)

PrivacyPolicyTemplate.propTypes = {
  location: Props.shape({
    search: Props.string,
  }).isRequired,
  pageContext: Props.any.isRequired,
}

export default PrivacyPolicyTemplate
